import styled from 'styled-components';

export const Header = styled.header`
  z-index: 2;
  position: sticky;
  top: 0;
  background-color: ${(props) => props.theme.colors.primary['50']};
  padding-top: calc(var(--header-height) * 2);

  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    padding-top: calc(var(--header-height) * 2);
  }
`;
export const HeaderWrapper = styled.div`
  position: sticky;

  &::after {
    content: '';
    width: 2px;
    height: 100%;
    background-color: ${(props) => props.theme.colors.grey.dark};
    position: absolute;
    top: 0;
    right: 0;
    border-radius: 1000px 1000px 0 0;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    &::after {
      display: none;
    }
  }
`;
export const Title = styled.h2`
  font-family: ${(props) => props.theme.font.family.heading};
  font-size: 9.1vmin;
  padding-top: 24px;
  padding-bottom: 2.5vmin;
  max-width: 992px;
  font-style: normal;
  line-height: 10vmin;
  letter-spacing: -1.5px;
  color: ${(props) => props.theme.colors.grey.dark};
  font-weight: inherit;

  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    padding-top: 16px;
  }
`;
