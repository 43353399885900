import Link from 'next/link';
import Image from 'next/image';
import ArrowRight from '@swordhealth/ui-corporate/icons/system/chevron-right-1px.svg';
import getImgSrcWorkaround from '@/utils/images';
import { Paragraph } from '@/components/core';

import { Logo, Wrapper, Content, Icon } from './styles';

const HeaderBanner = ({ content }) => {
  const { text, logo, link, active } = content;

  if (!active) return null;

  return (
    <Wrapper>
      <Link href={link} target="_blank">
        <Logo>
          <Image
            src={getImgSrcWorkaround(logo?.data?.attributes?.url)}
            alt={logo?.data?.attributes?.alternativetext || ''}
            width={59}
            height={16}
          />
        </Logo>
        <Content>
          <Paragraph size="sm" as="p" className="text">
            {text}
          </Paragraph>
          <Icon>
            <ArrowRight />
          </Icon>
        </Content>
      </Link>
    </Wrapper>
  );
};

export default HeaderBanner;
