import Image from 'next/image';
import getImgSrcWorkaround from '@/utils/images';
import Link from 'next/link';

import {
  Wrapper,
  Content,
  Icon,
  Title,
  LinkWrapper,
  ImageWrapper,
  Logo,
  LogoElement,
} from './style';

const Card = ({ content }) => {
  const { title, link, logo, imageBackground, highlightColor } = content;

  return (
    <Wrapper $highlightColor={`${highlightColor}`}>
      <Link href={link.href} target="_blank">
        <Content $highlightColor={`${highlightColor}`}>
          <Icon aria-hidden="true" />
          <Title size="xl">{title}</Title>
          <LinkWrapper>
            {link.label}
            <Image src="/assets/icons/press-icon.svg" alt="" width={16} height={16} />
          </LinkWrapper>
        </Content>
        <ImageWrapper $highlightColor={`${highlightColor}`}>
          <Image
            src={getImgSrcWorkaround(imageBackground.data?.attributes.url)}
            alt={imageBackground.data?.attributes.alternativeText || ''}
            fill
          />
          <Logo>
            <LogoElement>
              <Image
                src={getImgSrcWorkaround(logo.data?.attributes.url)}
                alt={logo.data?.attributes.alternativeText || ''}
                fill
              />
            </LogoElement>
          </Logo>
        </ImageWrapper>
      </Link>
    </Wrapper>
  );
};

export default Card;
