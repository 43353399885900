import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  position: relative;

  &::after,
  &::before {
    content: '';
    width: 2px;
    height: 100%;
    position: absolute;
    top: 0;
    right: 0;
    z-index: 1;
    background-color: ${(props) => props.theme.colors.grey.dark};
    transition: all 800ms ease-in-out;
  }
  &::before {
    opacity: 0.1;
  }
  &::after {
    height: 0;
    opacity: 0;
    ${(props) =>
      props.$isActiveBar &&
      css`
        height: 100%;
        opacity: 1;
        border-radius: 1000px 1000px 0 0;
      `};
    ${(props) =>
      props.$isFirst &&
      css`
        height: 100%;
        opacity: 1;
        border-radius: 1000px 1000px 0 0;
      `};
  }

  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    &::before,
    &::after {
      display: none;
    }
  }
`;
export const TextElement = styled.p`
  font-family: ${(props) => props.theme.font.family.heading};
  font-size: 9.1vmin;
  max-width: 992px;
  font-style: normal;
  font-weight: inherit;
  line-height: 10vmin;
  letter-spacing: -1.5px;
  color: ${(props) => props.theme.colors.primary.default};
  opacity: 0.1;
  padding-bottom: 2.5vmin;
  transition: all 200ms ease-in-out;
  position: sticky;
  top: 34vh;

  ${(props) =>
    props.$isFirst &&
    props.$isActive &&
    css`
      opacity: 1;
    `};
  ${(props) =>
    props.$isActive &&
    css`
      opacity: 1;
    `};
`;
