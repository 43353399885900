'use client';

import { useEffect, useState, useRef } from 'react';
import Slider from 'react-slick';
import { getLogo } from '@/services/careers';
import useReducedMotion from '@/utils/useReducedMotion';
import Card from './Card';
import { Intro } from '@/components/shared';
import ThumbList from './ThumbList';

import { StyledSection, CustomContainer, List, ThumbWrapper } from './styles';

const defaultSettings = {
  lazyLoad: true,
  asNavFor: '.slider-nav',
  focusOnSelect: true,
  autoplay: true,
  autoplaySpeed: 15000,
  arrows: false,
  infinite: true,
  responsive: [
    {
      breakpoint: 768,
      settings: {
        dots: true,
        centerPadding: '28px',
        centerMode: true,
        autoplay: false,
      },
    },
    {
      breakpoint: 480,
      settings: {
        dots: true,
        centerPadding: '24px',
        centerMode: true,
        autoplay: false,
      },
    },
  ],
};

const Press = ({ content }) => {
  const { upperLabel, title, pressCard } = content;
  const isReduced = useReducedMotion();
  const [nav1, setNav1] = useState(null);
  const [currentSlide, setCurrentSlide] = useState(0);
  const [slider1, setSlider1] = useState(null);
  const logosEls = useRef([]);

  const settings = {
    onReInit: () => setCurrentSlide(slider1?.innerSlider.state.currentSlide),
    ...defaultSettings,
  };

  useEffect(() => {
    setNav1(slider1);
    const handleLogo = async () => {
      pressCard.map(async (item, id) => {
        const response = await getLogo(item.logo.data.attributes.url);
        logosEls.current[id].innerHTML = `${response}`;
      });
    };

    handleLogo();
  }, [slider1, pressCard]);

  return (
    <StyledSection md={200} xs={96}>
      <Intro
        align="left"
        title={title}
        titleSize="3xl"
        upperLabel={upperLabel}
        upperLabelVariant="badge"
        innerSpacer
      />
      <CustomContainer>
        <List>
          <Slider {...settings} asNavFor={nav1} ref={(slider) => setSlider1(slider)}>
            {pressCard.map((item) => (
              <Card key={item.id} content={item} />
            ))}
          </Slider>
        </List>
        <ThumbWrapper>
          {pressCard.map((item, index) => (
            <ThumbList
              key={index}
              index={index}
              currentSlide={currentSlide}
              handleClick={() => {
                slider1?.slickGoTo(index);
              }}
              isReduced={isReduced}
              refElement={logosEls}
            ></ThumbList>
          ))}
        </ThumbWrapper>
      </CustomContainer>
    </StyledSection>
  );
};

export default Press;
