'use client';

import React, { memo, useEffect, useMemo, useState } from 'react';
import { Dot, Line, Section, Square, Wrapper } from './style';

// create a random value far from the one received
function getRandomNumberWithMargin(prev) {
  if (!prev) return Math.random();
  const excludedRange = prev + 0.2 - (prev - 0.2);
  let rand = Math.random() * (1 - excludedRange);
  if (rand > prev - 0.2) rand += excludedRange;
  return rand;
}

const Animation = ({ children }) => {
  const GRID_WIDTH = 160;
  const [lines, setLines] = useState(0);
  const [seed, setSeed] = useState([]);
  const [dotCoordinates, setDotCoordinates] = useState([]);
  const [squareCoordinates, setSquareCoordinates] = useState([]);

  useEffect(() => {
    // calculate the number of red lines that will be animated
    function calcLines() {
      setLines(Math.floor(window.innerWidth / GRID_WIDTH));
    }
    calcLines();

    // recalculate number of lines when a window resize happens
    window.addEventListener('resize', calcLines);
    return () => {
      window.removeEventListener('resize', calcLines);
    };
  }, []);

  useMemo(() => {
    setSeed([]);
    setDotCoordinates([]);
    setSquareCoordinates([]);
    for (let i = 0; i < lines; i++) {
      // set a random value seed for each line
      setSeed((current) => [...current, getRandomNumberWithMargin(current[i - 1])]);

      // set random value coordinates for each dot
      setDotCoordinates((current) => [
        ...current,
        {
          // calculate the coordinate random value from the previous iteration and get a new random one
          // the coordinates should be an integer excluding 0
          x: Math.floor(getRandomNumberWithMargin((current[i - 1]?.x - 1) / lines) * lines + 1),

          // for the y coordinate we're using the window height to calculate the number of horizontal lines
          y: Math.floor(
            getRandomNumberWithMargin((current[i - 1]?.y - 1) / (window.innerHeight / GRID_WIDTH)) *
              (window.innerHeight / GRID_WIDTH) +
              1,
          ),
        },
      ]);

      // set random value coordinates for each square
      setSquareCoordinates((current) => [
        ...current,
        {
          x: Math.floor(getRandomNumberWithMargin((current[i - 1]?.x - 1) / lines) * lines + 1),
          y: Math.floor(
            getRandomNumberWithMargin((current[i - 1]?.y - 1) / (window.innerHeight / GRID_WIDTH)) *
              (window.innerHeight / GRID_WIDTH) +
              1,
          ),
        },
      ]);
    }
  }, [lines]);

  return (
    <Section>
      <Wrapper $gridWidth={GRID_WIDTH}>
        {[...Array(lines)].map((elementInArray, index) => (
          <React.Fragment key={index}>
            <Line key={`line-${index}`} $seed={seed[index]} />
            <Dot key={`dot-${index}`} $x={dotCoordinates[index]?.x} $y={dotCoordinates[index]?.y} />
            <Square
              key={`square-${index}`}
              $x={squareCoordinates[index]?.x}
              $y={squareCoordinates[index]?.y}
            />
          </React.Fragment>
        ))}
      </Wrapper>
      {children}
    </Section>
  );
};

export default memo(Animation);
