import MarkdownRichText from '@/components/shared/MarkdownRichText';

import { Wrapper, CustomContainer, AnimatedButton, ArrowDown, CustomTitle } from './styles';
import Arrow from 'public/assets/icons/arrow_down_black.svg';
import HeaderRing from 'public/assets/shapes/careers_header_ring.svg';
import useUrlAttributes from '@/utils/useUrlAttributes';
import { Paragraph } from '@/components/core';

const Header = ({ content }) => {
  const urlAttributes = useUrlAttributes('#mission');

  if (!content) return null;

  const { title, subtitle, textButton } = content;

  return (
    <Wrapper as="section">
      <HeaderRing aria-hidden={true} />
      <HeaderRing aria-hidden={true} />
      <CustomContainer as="header">
        <CustomTitle forwardedAs="h1" size="4xl">
          <MarkdownRichText>{title}</MarkdownRichText>
        </CustomTitle>
        <Paragraph>{subtitle}</Paragraph>
        <AnimatedButton aria-label={textButton} href="#mission" forwardedAs="a" {...urlAttributes}>
          <ArrowDown>
            <Arrow aria-hidden="true" />
          </ArrowDown>
        </AnimatedButton>
      </CustomContainer>
    </Wrapper>
  );
};
export default Header;
