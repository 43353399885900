import styled from 'styled-components';
import { Container } from '@/components/core/flexbox';
import { Section } from '@/components/core';

export const StyledSection = styled(Section)`
  background: linear-gradient(180deg, #f7f4f2 0%, #fff 100%);
`;

export const CustomContainer = styled(Container)`
  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    padding: 0;
  }
`;

export const Header = styled.header`
  justify-items: start;
  display: grid;
  gap: 24px;
  margin-bottom: 80px;
  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    margin-bottom: 64px;
  }
  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    gap: 16px;
    margin-bottom: 40px;
    text-align: center;
  }
`;

export const List = styled.div`
  .slick-list {
    border-radius: 16px;
  }

  .slick-dots {
    bottom: -40px;
    li {
      width: 8px;
      height: 8px;
      margin: 0 4px;
      button {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0;
        width: 8px;
        height: 8px;
        &:before {
          position: relative;
          content: '';
          width: 8px;
          height: 8px;
          background-color: transparent;
          border: 2px solid ${(props) => props.theme.colors.grey[500]};
          border-radius: 50%;
          opacity: 1;
        }
      }
    }
    .slick-active button:before {
      background-color: ${(props) => props.theme.colors.grey[500]};
    }
  }
  .slick-arrow {
    display: none;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    .slick-list {
      border-radius: 0;
    }
    .slick-slide {
      padding: 0 6px;
    }
  }
`;
export const ThumbWrapper = styled.ul`
  display: grid;
  grid-template-columns: repeat(3, minmax(200px, 1fr));
  grid-gap: 24px;
  margin-top: 24px;

  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    display: none;
  }
`;
