'use client';

import { useEffect, useRef, useState } from 'react';
import { Section } from '@/components/core';
import { Container } from '@/components/core/flexbox';
import theme from '@/utils/styles-variables';
import Text from './Text';
import { Header, Title, HeaderWrapper } from './styles';
import { Badge } from '@/components/core';

const OurCulture = ({ content }) => {
  const { title, upperLabel, cultures } = content;
  const refTitle = useRef(null);
  const [position, setPosition] = useState(0);

  useEffect(() => {
    setPosition(refTitle?.current?.clientHeight || 0);
  }, []);

  return (
    <Section color={theme.colors.primary['50']}>
      <Container>
        <Header ref={refTitle}>
          <HeaderWrapper>
            <Badge>{upperLabel}</Badge>
            <Title>{title}</Title>
          </HeaderWrapper>
        </Header>
        {cultures.slice(0, cultures.length - 1).map((item, idx) => (
          <Text
            key={item.id}
            idx={idx}
            id={item.id}
            text={item.text}
            positionHeader={position}
            length={cultures.length}
          />
        ))}
      </Container>
      <Container>
        <Text
          idx={cultures.length}
          id={cultures[cultures.length - 1].id}
          text={cultures[cultures.length - 1].text}
          positionHeader={position}
          length={cultures.length}
        />
      </Container>
    </Section>
  );
};

export default OurCulture;
