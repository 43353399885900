import { Section } from '@/components/core';
import Slider from 'react-slick';
import useBetterMediaQuery from '@/utils/useBetterMediaQuery';
import theme from '@/utils/styles-variables';
import Card from './Card';

import { Intro } from '@/components/shared';

import { List, CustomContainer } from './style';

const settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  arrows: false,
  centerPadding: '58px',
  centerMode: true,
  responsive: [
    {
      breakpoint: 480,
      settings: {
        centerPadding: '18px',
      },
    },
  ],
};

const Awards = ({ content }) => {
  const { upperLabel, title, awardsCard } = content;
  const isTablet = useBetterMediaQuery(`(max-width: ${theme.breakpoints.max_md}px)`);

  return (
    <Section xl={200} xs={96} sm={136}>
      <Intro
        title={title}
        titleSize="3xl"
        upperLabel={upperLabel}
        upperLabelVariant="badge"
        innerSpacer
      />
      <CustomContainer>
        <List>
          {isTablet ? (
            <Slider {...settings}>
              {awardsCard.map((item) => (
                <Card content={item} key={item.id} isTablet={isTablet} />
              ))}
            </Slider>
          ) : (
            awardsCard.map((item) => <Card content={item} key={item.id} isTablet={isTablet} />)
          )}
        </List>
      </CustomContainer>
    </Section>
  );
};

export default Awards;
