import styled, { css } from 'styled-components';
import Image from 'next/image';
import DateComponent from '@/components/shared/Blog/DateComponent';
import Link from 'next/link';
import ArrowButtonIcon from '@/public/assets/icons/arrow-button.svg';

export const Post = ({ postData, big }) => {
  const { title, featuredImage, content, category, slug } = postData;

  return (
    <Link href={`/newsroom/${slug}`}>
      <Wrapper $big={big}>
        <ImageWrapper $big={big}>
          <CustomImage
            src={featuredImage?.data?.attributes.url}
            alt={featuredImage?.data?.attributes.alternativeText || ''}
            fill
            sizes={`(max-width: 767px) 100vw, (max-width: 991px) 45vw, ${big ? '612px' : '180px'}`}
          />
        </ImageWrapper>
        <Content>
          <DateComponent
            content={content}
            category={category.data.attributes.displayName}
            hasReadingTime
            hideDate
          />

          <Title>{title}</Title>

          <CallToRead>
            Read the full story
            <ArrowButtonIcon aria-hidden="true" />
          </CallToRead>
        </Content>
      </Wrapper>
    </Link>
  );
};

const ImageWrapper = styled.figure`
  overflow: hidden;
  border-radius: 8px;
  width: 100%;
  aspect-ratio: 1.77;
  position: relative;

  ${({ $big }) =>
    !$big &&
    css`
      @media (min-width: ${(props) => props.theme.breakpoints.min_lg}px) {
        max-width: 180px;
        aspect-ratio: 1.5;
      }
    `}
`;

const CustomImage = styled(Image)`
  object-fit: cover;
  transition: transform 300ms ease-in-out;
`;

const CallToRead = styled.div`
  font-size: 16px;
  font-weight: 500;
  font-family: ${(props) => props.theme.font.family.heading};
  line-height: 24px;
  color: ${(props) => props.theme.colors.grey.dark};
  opacity: 1;

  svg {
    margin-left: 4px;
    width: 8px;
    height: 13px;
    vertical-align: middle;
  }

  @media (min-width: ${(props) => props.theme.breakpoints.min_md}px) {
    opacity: 0;
    transition: all 300ms ease-in-out;
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 24px;

  @media (min-width: ${(props) => props.theme.breakpoints.min_md}px) {
    &:hover {
      ${CallToRead} {
        opacity: 1;
      }

      ${CustomImage} {
        transform: scale(105%);
      }
    }
  }

  ${({ $big }) =>
    !$big &&
    css`
      @media (min-width: ${(props) => props.theme.breakpoints.min_lg}px) {
        flex-direction: row;
        align-items: center;
      }
    `}
`;

const Content = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 8px;

  [class^='DateComponent__Category'] {
    flex-basis: unset;
  }
`;

const Title = styled.h3`
  font-family: ${(props) => props.theme.font.family.heading};
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
  color: ${(props) => props.theme.colors.grey.dark};
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
`;
