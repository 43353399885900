import { memo } from 'react';
import { Paragraph } from '@/components/core';

const Disclaimer = ({ content }) => (
  <Paragraph data-testid="join-us-disclaimer" markdownProps={{ elements: ['p', 'h4', 'h5', 'h6'] }}>
    {content}
  </Paragraph>
);

export default memo(Disclaimer);
