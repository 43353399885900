import { CardWrapper, Content, ContentTitle, ImageWrapper, ImageHover } from './style';
import Image from 'next/image';
import getImgSrcWorkaround from '@/utils/images';

import { ArrowIndicator } from '../../shared';

const Card = ({ content, isTablet }) => {
  const { link, image, imageHover, title } = content;

  return (
    <CardWrapper href={link} target="_blank">
      <ImageWrapper>
        <Image
          src={getImgSrcWorkaround(image.data?.attributes.url)}
          alt={image.data?.attributes.alternativeText || ''}
          fill
        />
        {!isTablet && (
          <ImageHover>
            <Image
              src={getImgSrcWorkaround(imageHover.data?.attributes.url)}
              alt={imageHover.data?.attributes.alternativeText || ''}
              fill
            />
          </ImageHover>
        )}
      </ImageWrapper>
      <Content>
        <ContentTitle size="lg">{title}</ContentTitle>
        <ArrowIndicator />
      </Content>
    </CardWrapper>
  );
};

export default Card;
