'use client';

import Link from 'next/link';
import ArrowRight from '@swordhealth/ui-corporate/icons/system/arrow-right-2px.svg';

import theme from '@/utils/styles-variables';
import useBetterMediaQuery from '@/utils/useBetterMediaQuery';
import { Button } from '@/components/core/Button';
import { Layout, Title } from '@/components/core';
import Section from '@/components/shared/Legal/Section';
import {
  Cta,
  Empty,
  HeaderJoinUs,
  PostingContainer,
  PostingCategories,
} from '@/components/company/careers';

import { Wrapper, Info, ListJobs } from './styles';
import { unslugifyDepartment } from '@/services/careers';

const DepartmentPageContent = ({ bannerInfo, content, postings, slug }) => {
  const isMobile = useBetterMediaQuery(`(max-width: ${theme.breakpoints.max_sm}px)`);
  const { joinUs, seo, title } = content;

  return (
    <Layout bannerInfo={bannerInfo}>
      <Section>
        <PostingContainer>
          <HeaderJoinUs title={title} description={seo.description} />
          <Wrapper innerSpacer>
            {postings.length > 0 ? (
              postings?.map((item, index) => (
                <li key={index}>
                  <Title as="h4" size="xl" className="title">
                    {item.name}
                  </Title>
                  <ul>
                    {item.list?.map((list, index) => (
                      <ListJobs key={index}>
                        <Link href={`/company/careers/${slug}/${list.id}`}>
                          <Info>
                            <Title as="p" size="lg">
                              {list.text}
                            </Title>
                            <PostingCategories posting={list} />
                          </Info>
                          <Button
                            variant="secondary"
                            as="div"
                            rounded={isMobile}
                            className="apply"
                            size={isMobile ? 'sm' : 'md'}
                          >
                            {isMobile ? <ArrowRight title="Apply" /> : 'Apply'}
                          </Button>
                        </Link>
                      </ListJobs>
                    ))}
                  </ul>
                </li>
              ))
            ) : (
              <Empty department={unslugifyDepartment(slug)} />
            )}
          </Wrapper>
        </PostingContainer>
        {joinUs.cta && postings?.length > 0 && <Cta content={joinUs.cta} />}
      </Section>
    </Layout>
  );
};

export default DepartmentPageContent;
