import styled from 'styled-components';
import Link from 'next/link';

import { Heading } from '@swordhealth/ui-corporate';

export const ImageWrapper = styled.figure`
  height: 240px;
  position: relative;

  img {
    object-fit: cover;
    border-radius: 16px 16px 0 0;
    border: 1px solid ${(props) => props.theme.colors.grey['200']};
    border-bottom: 0;
    transition: all 120ms ease-in-out;
    opacity: 1;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    width: 50%;

    img {
      border-radius: 16px 0 0 16px;
    }
  }

  @media (max-width: ${(props) => props.theme.breakpoints.max_xs}px) {
    width: 100%;
    height: 200px;

    img {
      border-radius: 16px 16px 0 0;
    }
  }
`;
export const Content = styled.div`
  display: flex;
  align-items: center;
  padding: 16px 24px 16px 32px;
  justify-content: space-between;
  border-radius: 0 0 16px 16px;
  border: 1px solid ${(props) => props.theme.colors.grey['200']};
  transition: all 120ms ease-in-out;

  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    padding: 32px;
    width: 50%;
    border-radius: 0 16px 16px 0;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.max_xs}px) {
    width: 100%;
    padding: 16px 24px;
    border-radius: 0 0 16px 16px;
  }
`;
export const ContentTitle = styled(Heading)`
  max-width: 75%;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  transition: all 120ms ease-in-out;

  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    max-width: 80%;
  }
`;

export const ImageHover = styled.div`
  height: 240px;
  position: relative;
  overflow: hidden;
  border-radius: 16px 16px 0 0;

  img {
    opacity: 0;
    transform: scale(1);
    transition: all 120ms ease-in-out;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    border-radius: 16px 0 0 16px;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.max_xs}px) {
    border-radius: 16px 16px 0 0;
  }
`;
export const CardWrapper = styled(Link)`
  @media (min-width: ${(props) => props.theme.breakpoints.min_lg}px) {
    &:hover {
      ${Content} {
        background-color: ${(props) => props.theme.colors.primary.dark};
        border-color: ${(props) => props.theme.colors.primary.dark};
      }
      ${ContentTitle} {
        color: ${(props) => props.theme.colors.white};
      }
      ${ImageWrapper} {
        img {
          opacity: 0;
        }
      }
      ${ImageHover} {
        img {
          opacity: 1;
          transform: scale(105%);
        }
      }
    }
  }

  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    display: flex;

    svg {
      fill: ${(props) => props.theme.colors.grey.dark};
    }
  }

  @media (max-width: ${(props) => props.theme.breakpoints.max_xs}px) {
    display: grid;
    grid-template-rows: 200px auto;
    height: 100%;
  }
`;
