import styled, { css, keyframes } from 'styled-components';

const progress = keyframes`
  from {
    width: 0%;
  }
  
  to {
    width: 100%;
  }
`;

export const ThumbListElement = styled.li`
  position: relative;
  padding-top: 32px;
  width: 100%;
  display: flex;
  justify-content: center;
  cursor: pointer;

  &:before,
  &:after {
    content: '';
    width: 100%;
    height: 2px;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
  }

  &:after {
    width: 0;
    z-index: 2;
    border-radius: 1000px;
    background-color: ${(props) => props.theme.colors.grey.dark};

    ${(props) =>
      props.$isReduced &&
      css`
        display: none;
      `};
  }
  &:before {
    background-color: ${(props) => props.theme.colors.grey.light};
  }

  svg {
    opacity: 0.2;
    transition: all 120ms ease-in-out;
    path {
      fill: ${(props) => props.theme.colors.grey.dark};
    }

    &:hover {
      opacity: 1;
    }
  }

  &.active {
    &:after {
      animation: ${progress} 15s linear forwards;
    }
    &:before {
      ${(props) =>
        props.$isReduced &&
        css`
          background-color: ${props.theme.colors.grey.dark};
        `};
    }
    svg {
      opacity: 1;
    }
  }
`;
