import { BASE_CONF } from '@/components/core/flexbox/config';
import styled from 'styled-components';

export const Logo = styled.figure`
  background-color: ${(props) => props.theme.colors.neutral.default};
  padding: 8px 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 12px;
  transition: all 120ms ease-in-out;

  @media (max-width: ${(props) => props.theme.breakpoints.max_xs}px) {
    padding: 4px 8px;

    img {
      width: 44px;
      height: 12px;
      object-fit: contain;
    }
  }
`;

export const Content = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;
export const Icon = styled.div`
  display: flex;
  transition: all 120ms ease-in-out;
`;

export const Wrapper = styled.div`
  position: absolute;
  top: calc(var(--header-height) + 16px);
  left: 50%;
  transform: translateX(-50%);
  z-index: 2;
  width: 100%;
  display: flex;
  justify-content: center;

  a {
    display: flex;
    gap: 8px;
    padding: 4px 16px 4px 4px;
    background-color: ${(props) => props.theme.colors.neutral.dark};
    border-radius: 16px;
    align-items: normal;
    transition: all 120ms ease-in-out;

    &:hover {
      background-color: ${(props) => props.theme.colors.neutral.light};
      box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.1);

      ${Icon} {
        transform: translateX(8px);
      }
    }
  }

  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    padding: 0 ${BASE_CONF.mobileOuterMargin}px;
    top: calc(var(--header-height) + 8px);
  }
  @media (max-width: ${(props) => props.theme.breakpoints.max_xs}px) {
    .text {
      font-size: 12px;
      line-height: 20px;
    }
  }
`;
