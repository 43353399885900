import { ThumbListElement } from './style';

const ThumbList = ({ index, currentSlide, isReduced, refElement, handleClick }) => {
  return (
    <ThumbListElement
      key={index}
      className={currentSlide === index ? 'active' : null}
      onClick={handleClick}
      $isReduced={isReduced}
      ref={(el) => (refElement.current[index] = el)}
    />
  );
};

export default ThumbList;
