import styled, { keyframes } from 'styled-components';

import BigRedShape from '@/public/assets/company/big_red_shape.svg';
import SmallRedShape from '@/public/assets/company/small_red_shape.svg';
import BigLightBlueShape from '@/public/assets/company/big_light_blue_shape.svg';
import SmallLightBlueShape from '@/public/assets/company/small_light_blue_shape.svg';
import BigDarkBlueShape from '@/public/assets/company/big_dark_blue_shape.svg';
import SmallDarkBlueShape from '@/public/assets/company/small_dark_blue_shape.svg';

export const Wrapper = styled.div`
  position: relative;
  display: flex;
  height: 1200px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: stretch;

  @media (max-width: ${(props) => props.theme.breakpoints.max_lg}px) {
    height: 800px;
    min-height: 800px;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    height: auto;
    min-height: auto;
  }
`;

export const ContentContainer = styled.div`
  display: flex;
  max-width: 640px;
  flex-direction: column;
  align-items: center;
  text-align: center;
  gap: 24px;

  @media (max-width: ${(props) => props.theme.breakpoints.max_lg}px) {
    max-width: 480px;
  }
  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    padding: 0 36px;
    margin-bottom: 64px;
    gap: 16px;
  }
  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    padding: 0 24px;
    margin-bottom: 40px;
  }
`;

export const TestimonialsWrapper = styled.div`
  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    position: relative;
  }
`;

const popUp = keyframes`
  from {
    transform: scale(0%);
    opacity: 0;
  }
  to {
    transform: scale(100%);
    opacity: 1;
  }
`;

export const TestimonialContainer = styled.div`
  @media (min-width: ${(props) => props.theme.breakpoints.min_lg}px) {
    position: absolute;
    opacity: 0;
    animation-name: ${({ $animate }) => ($animate ? popUp : 'none')};
    animation-direction: normal;
    animation-duration: 400ms;
    animation-delay: ${({ $delaySeed }) => $delaySeed * 150}ms;
    animation-fill-mode: forwards;
    animation-iteration-count: 1;
    animation-timing-function: linear;

    &:first-child {
      top: 20%;
      left: 24%;
    }
    &:nth-child(2) {
      top: 29%;
      left: 72%;
    }
    &:nth-child(3) {
      top: 58%;
      left: 79%;
    }
    &:nth-child(4) {
      top: 78%;
      left: 55%;
    }
    &:last-child {
      top: 62%;
      left: 14%;
    }
  }

  @media (min-width: ${(props) => props.theme.breakpoints.min_lg}px) and (max-width: ${(props) =>
      props.theme.breakpoints.max_lg}px) {
    &:first-child {
      top: 7%;
      left: 20%;
    }
    &:nth-child(2) {
      top: 11%;
      left: 70%;
    }
    &:nth-child(3) {
      top: 56%;
      left: 77%;
    }
    &:nth-child(4) {
      top: 85%;
      left: 52%;
    }
    &:last-child {
      top: 61%;
      left: 9%;
    }
  }

  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    position: relative;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    position: static;
  }
`;

export const ImageWrapper = styled.a`
  cursor: pointer;
  display: flex;
  transform-style: preserve-3d;
  transition: all 120ms ease-in-out;

  img {
    border: solid 2px white;
    object-fit: cover;
    border-radius: 100%;
    max-width: 100%;
    height: auto;
    aspect-ratio: 1/1;
  }

  &:before,
  &:after {
    position: absolute;
    content: '';
    width: 180px;
    height: 180px;
    top: 50%;
    left: 50%;
    background-color: white;
    border-radius: 100%;
    transform: translate3d(-50%, -50%, -1px);
    pointer-events: none;

    @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
      display: none;
    }
  }

  &:after {
    display: block;
    width: 240px;
    height: 240px;
    opacity: 0.5;

    @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
      display: ${(props) => !props.$isActive && `none`};
      width: 168px;
      height: 168px;
      background-color: rgba(255, 255, 255, 0.5);
      border: solid 2px white;
    }

    @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
      display: none;
    }
  }

  @media (min-width: ${(props) => props.theme.breakpoints.min_lg}px) {
    &:hover {
      transform: scale(1.05);
    }
  }

  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    margin-left: -12px;
    margin-right: -12px;
    height: 120px;
    width: 120px;
    transform: scale(0.666);

    &.active {
      margin-left: 0;
      margin-right: 0;
      transform: scale(1);
    }
  }

  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    height: 64px;
    width: 64px;
    margin-left: -4px;
    margin-right: -4px;
    transform: scale(0.75);
  }
`;

export const QuoteIconContainer = styled.div`
  pointer-events: none;
  position: absolute;
  top: 100%;
  left: 50%;
  display: flex;
  height: 44px;
  width: 44px;
  align-items: center;
  justify-content: center;
  gap: 8px;
  transition: all 120ms ease-in-out;

  transform: ${({ $show }) =>
    $show ? 'translate(-50%, calc(-50% + 8px))' : 'translate(-50%,  -50%)'};
  transition: all 120ms ease-in-out;

  border-radius: 32px;
  background: ${(props) => props.theme.colors.white};
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1);

  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    visibility: hidden;
    opacity: 0;
  }

  img {
    object-fit: contain;
  }
`;

export const StyledBigRedShape = styled(BigRedShape)`
  position: absolute;
  top: 20%;
  left: 11%;
  z-index: -1;

  @media (max-width: ${(props) => props.theme.breakpoints.max_lg}px) {
    top: 3%;
    left: -1%;
  }
  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    display: none;
  }
`;
export const StyledSmallRedShape = styled(SmallRedShape)`
  position: absolute;
  top: 77%;
  left: 33%;
  z-index: -1;

  @media (max-width: ${(props) => props.theme.breakpoints.max_lg}px) {
    top: 85%;
    left: 29%;
  }
  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    display: none;
  }
`;
export const StyledBigLightBlueShape = styled(BigLightBlueShape)`
  position: absolute;
  top: 64%;
  left: 69%;
  z-index: -1;

  @media (max-width: ${(props) => props.theme.breakpoints.max_lg}px) {
    top: 66%;
    left: 80%;
  }
  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    display: none;
  }
`;
export const StyledSmallLightBlueShape = styled(SmallLightBlueShape)`
  position: absolute;
  top: 42%;
  left: 14%;
  z-index: -1;

  @media (max-width: ${(props) => props.theme.breakpoints.max_lg}px) {
    top: 32%;
    left: 3%;
  }
  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    display: none;
  }
`;

export const StyledBigDarkBlueShape = styled(BigDarkBlueShape)`
  position: absolute;
  top: 65%;
  left: 23%;
  z-index: -1;

  @media (max-width: ${(props) => props.theme.breakpoints.max_lg}px) {
    top: 67%;
    left: 16%;
  }
  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    display: none;
  }
`;

export const StyledSmallDarkBlueShape = styled(SmallDarkBlueShape)`
  position: absolute;
  top: 23%;
  left: 58%;
  z-index: -1;

  @media (max-width: ${(props) => props.theme.breakpoints.max_lg}px) {
    top: 5%;
    left: 65%;
  }
  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    display: none;
  }
`;
