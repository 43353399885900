'use client';

// React
import { useCallback, useEffect, useRef, useState } from 'react';
import cn from 'classnames';

// Components
import Quote from '@/components/company/careers/Mission/Quote/Quote';
import { Title, Paragraph, Section } from '@/components/core';
import { Button } from '@/components/core/Button';
import { Badge } from '@/components/core';

// Utils
import useBetterMediaQuery from '@/utils/useBetterMediaQuery';
import { scrollToSection } from '@/utils/scrollToSection';
import getImgSrcWorkaround from '@/utils/images';
import theme from '@/utils/styles-variables';

// Image
import Image from 'next/image';

// Styles
import {
  Wrapper,
  ContentContainer,
  TestimonialsWrapper,
  TestimonialContainer,
  ImageWrapper,
  QuoteIconContainer,
  StyledBigRedShape,
  StyledSmallRedShape,
  StyledBigLightBlueShape,
  StyledSmallLightBlueShape,
  StyledBigDarkBlueShape,
  StyledSmallDarkBlueShape,
} from './styles';
import { useInView } from 'react-intersection-observer';

const Mission = ({ content }) => {
  const { upperLabel, title, description, textButton, quotes } = content;
  const [activeTooltip, setActiveTooltip] = useState(null);
  const [lastPosition, setLastPosition] = useState(null);
  const [marginBottom, setMarginBottom] = useState(0);
  const isDesktop = useBetterMediaQuery(`(min-width: ${theme.breakpoints.min_lg}px)`);
  const quoteEls = useRef([]);
  const [isVisible, setIsVisible] = useState(false);

  const [reference, inView] = useInView({
    threshold: 0,
    initialInView: false,
  });

  useEffect(() => {
    if (inView && !isVisible) {
      setIsVisible(true);
    }
  }, [inView]);

  useEffect(() => {
    const handleResize = () => {
      if (!isDesktop) {
        const max = quoteEls.current.reduce(
          (prev, current) => (prev && prev > current.offsetHeight ? prev : current.offsetHeight),
          0,
        );
        setActiveTooltip(2);
        setMarginBottom(max);
      } else {
        setActiveTooltip(null);
        setMarginBottom(0);
      }
    };
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [isDesktop]);

  const handleChangeTestimonial = useCallback(
    (index) => {
      setLastPosition(activeTooltip);
      setActiveTooltip(index);
    },
    [activeTooltip, setLastPosition, setActiveTooltip],
  );

  return (
    <Section>
      <Wrapper>
        <ContentContainer id="mission">
          <Badge>{upperLabel}</Badge>
          <Title size="4xl">{title}</Title>
          <Paragraph size="lg">{description}</Paragraph>
          <Button onClick={() => scrollToSection({ sectionId: 'join-us-section' })}>
            {textButton}
          </Button>
        </ContentContainer>
        <TestimonialsWrapper style={{ marginBottom }} ref={reference}>
          {quotes.map((quote, index) => (
            <TestimonialContainer key={index} $delaySeed={index} $animate={isVisible}>
              <ImageWrapper
                role="button"
                className={cn({ active: activeTooltip === index })}
                onMouseEnter={() => isDesktop && setActiveTooltip(index)}
                onMouseLeave={() => isDesktop && setActiveTooltip(null)}
                onClick={() => !isDesktop && handleChangeTestimonial(index)}
              >
                <Image
                  src={getImgSrcWorkaround(quote.image.data.attributes.url)}
                  alt={quote.image.data.attributes.alternativeText || ''}
                  width="120"
                  height="120"
                />
              </ImageWrapper>
              <QuoteIconContainer $show={activeTooltip === index}>
                <img src="/assets/icons/quote.svg" alt="" width="20" height="20" />
              </QuoteIconContainer>
              <Quote
                ref={(e) => (quoteEls.current[index] = e)}
                quote={quote.quote}
                author={quote.author}
                show={activeTooltip === index}
                direction={
                  lastPosition < activeTooltip ? index <= lastPosition : index < lastPosition
                }
              />
            </TestimonialContainer>
          ))}
        </TestimonialsWrapper>
        <StyledBigRedShape />
        <StyledSmallRedShape />
        <StyledBigLightBlueShape />
        <StyledSmallLightBlueShape />
        <StyledBigDarkBlueShape />
        <StyledSmallDarkBlueShape />
      </Wrapper>
    </Section>
  );
};

export default Mission;
