import { Card } from '@/components/company/careers/shared';

import { StyledQuantity } from './styles';

const JoinUsCard = ({ footer, isHighlight, ...rest }) => (
  <Card
    {...rest}
    footer={
      <StyledQuantity size="lg" data-testid="join-us-quantify">
        {footer}
      </StyledQuantity>
    }
    isHighlight={isHighlight}
    titlesize="xl"
  />
);

export default JoinUsCard;
