import { Container } from '@/components/core/flexbox';
import { Button } from '@/components/core/Button';
import theme from '@/utils/styles-variables';
import useBetterMediaQuery from '@/utils/useBetterMediaQuery';
import { Post } from './Post';
import PostsSlider from './PostsSlider';
import { CTA, CustomSection, Grid, SideCol } from './style';
import { Intro } from '@/components/shared';

const News = ({ content }) => {
  const { upperLabel, title, articles, linkLabel } = content;
  const isMobile = useBetterMediaQuery(`(max-width: ${theme.breakpoints.max_sm}px)`);
  return (
    <CustomSection color={theme.colors.white}>
      <Intro
        align="left"
        title={title}
        titleSize="3xl"
        upperLabel={upperLabel}
        upperLabelVariant="badge"
        innerSpacer
      />
      <Container>
        {isMobile ? (
          <PostsSlider posts={articles.data} />
        ) : (
          <Grid>
            <Post postData={articles.data[0].attributes} big />
            <SideCol>
              {articles.data.slice(1).map((item) => (
                <Post key={item.id} postData={item.attributes} />
              ))}
            </SideCol>
          </Grid>
        )}
        {linkLabel && (
          <CTA>
            <Button variant="secondary" href="/newsroom">
              {linkLabel}
            </Button>
          </CTA>
        )}
      </Container>
    </CustomSection>
  );
};

export default News;
