import { forwardRef } from 'react';
import cn from 'classnames';

// Styles
import { Wrapper, QuoteIconContainer, QuoteText, Author } from './styles';

const Quote = forwardRef((props, ref) => {
  const { quote, author, show, direction } = props;
  return (
    <Wrapper ref={ref} className={cn({ active: show })} $direction={direction}>
      <QuoteIconContainer>
        <img src="/assets/icons/quote.svg" alt="" width="20" height="20" />
      </QuoteIconContainer>
      <QuoteText>{quote}</QuoteText>
      <Author>{author}</Author>
    </Wrapper>
  );
});

Quote.displayName = 'Quote';

export default Quote;
