import styled from 'styled-components';
import { Container } from '@/components/core/flexbox';

export const List = styled.section`
  display: grid;
  grid-template-columns: repeat(3, minmax(216px, 1fr));
  grid-gap: 24px;

  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    display: block;

    .slick-track {
      display: grid;
      grid-auto-flow: column;
      grid-auto-rows: 1fr;
    }

    .slick-slide {
      padding: 0 6px;

      > div {
        height: 100%;
      }
    }

    .slick-dots {
      bottom: -40px;

      li {
        width: 8px;
        height: 8px;
        margin: 0 4px;

        button {
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 0;
          width: 8px;
          height: 8px;

          &:before {
            position: relative;
            content: '';
            width: 8px;
            height: 8px;
            background-color: transparent;
            border: 2px solid ${(props) => props.theme.colors.grey[500]};
            border-radius: 50%;
            opacity: 1;
          }
        }
      }

      .slick-active button:before {
        background-color: ${(props) => props.theme.colors.grey[500]};
      }
    }
  }
`;
export const CustomContainer = styled(Container)`
  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    padding: 0;
  }
`;
