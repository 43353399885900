import styled from 'styled-components';

import { Container } from '@/components/core/flexbox';
import { Title } from '@/components/core';

export const Wrapper = styled.div`
  display: flex;
  min-height: calc(100svh - var(--banner-height) * 1px);
  padding: var(--header-height) 0 0;
  position: relative;

  &:before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    width: 1200px;
    height: 1200px;
    z-index: -1;
    background: ${(props) => props.theme.colors.white};
    opacity: 0.6;
    border-radius: 100%;
    filter: blur(200px);
  }

  > svg {
    position: absolute;
    width: 800px;
    height: 800px;

    &:first-child {
      bottom: calc(50% - 200px);
      right: calc(50% + 268px);
      transform: rotateY(180deg);
    }
    &:nth-child(2) {
      left: calc(50% + 268px);
      top: calc(50% - 200px);
    }
  }

  @media (max-width: ${(props) => props.theme.breakpoints.max_lg}px) {
    &:before {
      width: 992px;
      height: 992px;
    }
  }

  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    &:before {
      width: 768px;
      height: 768px;
    }

    > svg {
      display: none;
    }
  }

  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    &:before {
      width: 642px;
      height: 642px;
      filter: blur(32px);
    }
  }

  @media (max-width: ${(props) => props.theme.breakpoints.max_xs}px) {
    &:before {
      width: 442px;
      height: 442px;
    }
  }
`;

export const CustomContainer = styled(Container)`
  text-align: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  gap: 24px;
  align-items: center;
  z-index: 1;
  padding-top: 64px;
  padding-bottom: 64px;
  max-width: 960px;

  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    gap: 16px;
  }
`;
export const CustomTitle = styled(Title)`
  white-space: pre-wrap;

  strong {
    color: ${(props) => props.theme.colors.primary.default};
  }
`;

export const AnimatedButton = styled.button`
  border-radius: 100px;
  cursor: pointer;
  transform-origin: 50% 50%;
  animation: jump 1000ms ease-in-out alternate infinite;
  border: 2px solid ${(props) => props.theme.colors.neutral.dark};
  width: 88px;
  height: 88px;
  display: flex;
  align-items: center;
  justify-content: center;

  span {
    transition: all 0.3s ease;
  }

  &:hover {
    animation-play-state: paused;
    border: 2px solid ${(props) => props.theme.colors.white};
    span {
      background-color: ${(props) => props.theme.colors.white};
    }
  }

  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    width: 56px;
    height: 56px;
    border: unset;

    &:hover {
      border: unset;
    }
  }

  @keyframes jump {
    0% {
      transform: translate3d(0, 0, 0);
    }

    100% {
      transform: translate3d(0, 30%, 0);
    }
  }
`;
export const ArrowDown = styled.span`
  background-color: ${(props) => props.theme.colors.neutral.dark};
  padding: 18px;
  border-radius: 100%;
  width: 56px;
  height: 56px;

  svg {
    width: 20px;
  }
`;
