import styled from 'styled-components';
import { Section, Title } from '@/components/core';

export const CustomSection = styled(Section)`
  position: relative;
  z-index: 1;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    clip-path: circle(farthest-side at 50% -500px);
    z-index: -1;

    @media (min-width: ${(props) => props.theme.breakpoints.min_sm}px) {
      clip-path: circle(farthest-side at 50% -1250px);
    }

    @media (min-width: ${(props) => props.theme.breakpoints.min_lg}px) {
      clip-path: circle(farthest-side at 50% -4000px);
    }
  }
`;

export const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 24px;
  grid-row-gap: 40px;

  @media (min-width: ${(props) => props.theme.breakpoints.min_lg}px) {
    grid-column-gap: 40px;
  }
`;

export const SideCol = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;

  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    display: contents;
  }
`;

export const CustomTitle = styled(Title)`
  margin: 24px 0 40px 0;
  padding-bottom: 0;
  @media (min-width: ${(props) => props.theme.breakpoints.min_md}px) {
    margin-bottom: 64px;
  }
  @media (min-width: ${(props) => props.theme.breakpoints.min_lg}px) {
    margin-bottom: 80px;
    font-size: 55px;
    line-height: 64px;
  }
`;

export const CTA = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 40px;

  a {
    display: block;
  }

  @media (min-width: ${(props) => props.theme.breakpoints.min_md}px) {
    margin-top: 64px;
  }
`;
