'use client';

import { useMemo } from 'react';

import { slugifyDepartment } from '@/services/careers';
import { GridCards } from '@/components/company/careers/shared';
import Disclaimer from './Disclaimer';
import JoinUsCard from './JoinUsCard';

const JoinUs = ({ content, departments }) => {
  const cards = useMemo(() => {
    return departments?.map(({ name, quantify }) => {
      const cmsData = content?.cards?.find(
        ({ leverDepartment }) => name.toLowerCase() === leverDepartment.toLowerCase(),
      );

      return {
        href: `/company/careers/${slugifyDepartment(name)}`,
        title: name,
        description: cmsData?.description,
        footer: `${quantify} open roles`,
      };
    });
  }, [content, departments]);

  return (
    <GridCards
      id="join-us-section"
      title={content.title}
      upperLabel={content.upperLabel}
      footer={<Disclaimer content={content.disclaimer} />}
    >
      {cards?.map((item) => (
        <JoinUsCard data-testid="join-us-department" key={item.title} {...item} />
      ))}
      {content.spontaneousApplication && (
        <JoinUsCard
          data-testid="join-us-spontaneous"
          title={content.spontaneousApplication.cardTitle}
          description={content.spontaneousApplication.cardDescription}
          footer={content.spontaneousApplication.cardButton}
          href="/company/careers/apply"
          isHighlight
        />
      )}
    </GridCards>
  );
};

export default JoinUs;
