import styled from 'styled-components';
import PressIcon from '@/public/assets/icons/ico_press.svg';
import { Heading } from '@swordhealth/ui-corporate';

export const Content = styled.div`
  width: 50%;
  background-color: ${(props) => props.$highlightColor};
  padding: 80px;
  padding-right: 0;
  display: grid;
  gap: 16px;
  align-content: center;

  @media (max-width: ${(props) => props.theme.breakpoints.max_lg}px) {
    padding: 48px;
    padding-right: 0;
  }
  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    padding: 32px;
    padding-right: 0;
  }
  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    padding: 24px;
    padding-right: 0;
  }
  @media (max-width: ${(props) => props.theme.breakpoints.max_xs}px) {
    width: 100%;
    order: 2;
    padding-top: 40px;
    padding-right: 24px;
  }
`;
export const Icon = styled(PressIcon)`
  width: 20px;
  height: 20px;
  display: block;

  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    display: none;
  }
`;
export const Title = styled(Heading)`
  color: ${(props) => props.theme.colors.white};
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;
  overflow: hidden;

  @media (max-width: ${(props) => props.theme.breakpoints.max_xs}px) {
    -webkit-line-clamp: 3;
  }
`;
export const LinkWrapper = styled.div`
  font-family: ${(props) => props.theme.font.family.heading};
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  color: ${(props) => props.theme.colors.white};
  display: flex;
  align-items: center;
  height: 0;
  opacity: 0;
  transition: all 120ms ease-in-out;

  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    height: auto;
    opacity: 1;
  }
`;
export const LinkIcon = styled.div`
  margin-left: 4px;
`;
export const ImageWrapper = styled.figure`
  width: 50%;
  height: 100%;
  position: relative;
  overflow: clip;

  img {
    object-fit: cover;
    transition: all 120ms ease-in-out;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.max_xs}px) {
    overflow: visible;
    width: 100%;
    order: 1;
  }

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    background: linear-gradient(
      to right,
      ${(props) => props.$highlightColor} 0%,
      ${(props) => props.$highlightColor}80 100%
    );
    width: 100%;
    height: calc(100% + 1px); // +1px to avoid a small line at the bottom in iOS

    @media (max-width: ${(props) => props.theme.breakpoints.max_xs}px) {
      background: linear-gradient(
        to top,
        ${(props) => props.$highlightColor} 0%,
        ${(props) => props.$highlightColor}80 100%
      );
    }
  }
`;

export const Logo = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 4;
  width: 100%;
  height: 100%;
`;

export const LogoElement = styled.div`
  width: 100%;
  height: 48px;
  position: absolute;
  top: calc(50% - 24px);

  img {
    max-height: 48px;
    object-fit: contain;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    height: 32px;
    top: calc(50% - 16px);
    img {
      height: 32px;
    }
  }
  @media (max-width: ${(props) => props.theme.breakpoints.max_xs}px) {
    height: 24px;
    top: initial;
    bottom: -24px;
    justify-content: flex-start;
    left: 24px;
    max-width: 20%;

    img {
      max-height: 24px;
      width: auto;
      min-width: 69px;
    }
  }
`;

export const Wrapper = styled.div`
  background-color: ${(props) => props.$highlightColor};
  border-radius: 16px;
  overflow: hidden;
  width: 100%;
  height: 480px;

  a {
    -webkit-user-drag: none;
    display: flex;
    height: 100%;
  }

  @media (min-width: ${(props) => props.theme.breakpoints.min_lg}px) {
    &:hover {
      ${LinkWrapper} {
        opacity: 1;
        height: 24px;
      }
      ${ImageWrapper} {
        img {
          transform: scale(105%);
        }
      }
      ${Logo} {
        img {
          transform: scale(1);
        }
      }
    }
  }

  @media (max-width: ${(props) => props.theme.breakpoints.max_lg}px) {
    height: 440px;
  }
  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    height: 360px;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.max_xs}px) {
    height: 336px;

    a {
      flex-direction: column;
    }
  }
`;
