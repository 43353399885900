import styled from 'styled-components';
import { Section } from '@/components/core';
import { innerSpacerValues } from '@/components/core/Section/Section';

export const Wrapper = styled(Section).attrs({
  forwardedAs: 'ul',
})`
  display: flex;
  flex-direction: column;
  gap: ${innerSpacerValues.$md}px;

  .title {
    margin-bottom: 8px;
  }

  &:last-child {
    padding-bottom: 0;
  }
`;
export const Info = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const ListJobs = styled.li`
  --item-offset: 16px;

  a {
    width: 100%;
    padding: 24px 0;
    gap: 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    transform-style: preserve-3d;

    &::before {
      background-color: ${(props) => props.theme.colors.neutral.dark};
      border-radius: 16px;
      content: '';
      display: block;
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translate3d(-50%, 0, -1px);
      height: 100%;
      width: calc(100% + var(--item-offset) * 2);
      opacity: 0;
      transition: opacity 120ms ease-in-out;
    }

    &:after {
      content: '';
      width: 100%;
      height: 1px;
      background-color: ${(props) => props.theme.colors.neutral.dark};
      position: absolute;
      bottom: 0;
      left: 0;
      transition: opacity 120ms ease-in-out;
      opacity: 1;
    }

    &:hover {
      &:before {
        opacity: 1;
      }

      &:after {
        opacity: 0;
      }
    }

    .apply {
      flex-shrink: 0;
    }
  }
`;
