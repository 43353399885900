'use client';

import { useMemo, useCallback, useState } from 'react';
import styled, { css } from 'styled-components';
import Slider from 'react-slick';
import { Post } from './Post';

const PostsSlider = ({ posts }) => {
  const [dragging, setDragging] = useState(false);
  const beforeChange = useCallback(() => setDragging(true), [setDragging]);
  const afterChange = useCallback(() => setDragging(false), [setDragging]);
  const onClick = useCallback(
    (e) => {
      if (dragging) {
        e.preventDefault();
      }
    },
    [dragging],
  );

  const settings = useMemo(
    () => ({
      infinite: false,
      arrows: false,
      slidesToShow: 1,
      slidesToScroll: 1,
      dots: true,
      afterChange,
      beforeChange,
      customPaging: (i) => <button type="button">{i + 1}</button>,
    }),
    [afterChange, beforeChange],
  );

  if (!posts || !posts.length) return null;

  return (
    <SliderWrapper dots={posts.length > 1}>
      <Slider {...settings}>
        {posts.map((post) => (
          <CustomPost postData={post.attributes} key={post?.id} onClick={onClick} />
        ))}
      </Slider>
    </SliderWrapper>
  );
};

const SliderWrapper = styled.div`
  margin-bottom: 0;

  ${(props) =>
    props.dots &&
    css`
      padding-bottom: 10px;
    `};

  .slick-dots {
    padding: 0 24px;
    text-align: center;
    height: 10px;
    bottom: -10px;
    margin: 0;
    li {
      &:first-child {
        margin-left: 0;
      }
      width: 10px;
      height: 10px;
      margin: 0 4px;
      top: -13px;
      &.slick-active {
        button {
          background-color: ${(props) => props.theme.colors.grey.dark};
        }
      }
      button {
        padding: 0;
        background-color: transparent;
        border: 2px solid ${(props) => props.theme.colors.grey.dark};
        width: 10px;
        height: 10px;
        border-radius: 50%;
        opacity: 0.3;
        &:before {
          display: none;
        }
      }
    }
  }

  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    .slick-slider {
      left: 50%;
      margin-left: -50vw;
      margin-right: -50vw;
      overflow: hidden;
      padding: 0 18px;
      right: 50%;
      width: 100vw;
    }

    .slick-list {
      overflow: visible;
    }
  }

  .slick-dots {
    bottom: 2px;
    position: relative;
    text-align: center;
  }

  .slick-slide:not(:only-child) {
    padding: 0 6px;
  }
`;

const CustomPost = styled(Post)`
  -webkit-user-drag: none;
  touch-action: none;
  display: grid;
  grid-template-rows: auto 1fr;
`;

export default PostsSlider;
