import styled from 'styled-components';

export const Wrapper = styled.blockquote`
  position: absolute;
  top: 100%;
  left: 50%;
  display: flex;
  width: 280px;
  padding: 40px 24px 24px 24px;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  border-radius: 16px;
  background: ${(props) => props.theme.colors.white};
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.05);
  visibility: hidden;
  opacity: 0;
  pointer-events: none;
  transition: all 120ms ease-in-out;

  &.active {
    opacity: 1;
    visibility: visible;
  }

  @media (min-width: ${(props) => props.theme.breakpoints.min_lg}px) {
    transform: translate(-50%, -22px);

    :hover > & {
      visibility: visible;
      opacity: 1;
      transform: translate(-50%, -16px);
    }
  }

  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    transform: translate(-50%, 8px);

    &.active {
      transition-delay: 120ms;
    }
  }

  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    width: calc(100vw - 48px);
    max-width: 360px;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    top: calc(100% + 16px);
    transform: ${({ $direction }) =>
      $direction ? 'translateX(calc(-50% - 80px))' : 'translateX(calc(-50% + 80px))'};

    &.active {
      transform: translateX(-50%);
    }
  }
`;
export const QuoteText = styled.p`
  text-align: center;
  font-family: ${(props) => props.theme.font.family.body};
  font-size: 14px;
  line-height: 24px;
`;

export const Author = styled.footer`
  text-align: center;
  font-family: ${(props) => props.theme.font.family.body};
  font-size: 14px;
  font-weight: 700;
  line-height: 24px;
`;

export const QuoteIconContainer = styled.div`
  position: absolute;
  top: 0;
  display: flex;
  height: 44px;
  width: 44px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 32px;

  img {
    object-fit: contain;
  }
`;
