import { GridCards, Card } from '@/components/company/careers/shared';

const GridValues = ({ content }) => {
  const { upperLabel, title, cards } = content;
  return (
    <GridCards title={title} upperLabel={upperLabel}>
      {cards?.map(({ id, ...item }) => (
        <Card key={id} {...item} isClickable={false} />
      ))}
    </GridCards>
  );
};

export default GridValues;
