import styled, { keyframes } from 'styled-components';
import { rgba } from 'polished';

const animateTop = keyframes`
    from {
        transform: translateY(-480px);
    }
    to {
        transform: translateY(100cqh);
    }
`;

const fadeIn = keyframes`
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
`;

export const Section = styled.div`
  overflow-x: clip;
  position: relative;
  width: 100vw;

  // fade out effect at the bottom of the section
  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 600px;
    background: linear-gradient(180deg, rgba(245, 242, 240, 0) 0%, #f5f2f0 100%);
    z-index: -1;
  }
`;

export const Wrapper = styled.div`
  --gridWidth: ${({ $gridWidth }) => $gridWidth}px;
  --borderWidth: 1px;
  --structureColor: rgba(0, 0, 0, 0.03);

  animation: ${fadeIn} 120ms linear 120ms;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
  white-space: nowrap;
  overflow: hidden;
  container-type: size;
  background-color: ${(props) => props.theme.colors.neutral.default};
  background-image: linear-gradient(
      var(--structureColor) var(--borderWidth),
      transparent var(--borderWidth)
    ),
    linear-gradient(90deg, var(--structureColor) var(--borderWidth), transparent var(--borderWidth));
  background-size: var(--gridWidth) var(--gridWidth);
  background-position: top left;
`;

export const Line = styled.div`
  top: 0;
  position: relative;
  display: inline-block;
  background-image: linear-gradient(
    to bottom,
    transparent,
    ${(props) => props.theme.colors.primary.default} 50%,
    transparent
  );
  height: calc(var(--gridWidth) * 3);
  margin-left: calc(var(--gridWidth) - var(--borderWidth));
  width: var(--borderWidth);
  filter: blur(1px);
  animation: ${animateTop} 10s linear infinite;

  // use the seed value to determine a random delay to the animation so all the lines look different
  animation-delay: calc(${({ $seed }) => $seed} * (-10s));
`;

export const Dot = styled.div`
  position: absolute;
  top: calc(var(--gridWidth) * ${({ $y }) => $y} - 27px);
  left: calc(var(--gridWidth) * ${({ $x }) => $x} - 27px);
  width: 54px;
  height: 54px;
  display: flex;
  justify-content: center;
  align-items: center;

  // outside circle
  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    border-radius: 100%;
    background: linear-gradient(
      135deg,
      ${(props) => rgba(props.theme.colors.primary.default, 0)},
      ${(props) => rgba(props.theme.colors.primary.default, 1)}
    );
    z-index: -1;
    opacity: 0.1;
  }

  // inside circle
  &:after {
    content: '';
    width: 8px;
    height: 8px;
    background: ${(props) => props.theme.colors.primary.default};
    border-radius: 8px;
  }
`;

export const Square = styled.div`
  position: absolute;
  top: calc(var(--gridWidth) * ${({ $y }) => $y});
  left: calc(var(--gridWidth) * ${({ $x }) => $x});
  width: var(--gridWidth);
  height: var(--gridWidth);
  background: linear-gradient(135deg, rgba(0, 0, 0, 0), rgba(0, 0, 0, 1));
  z-index: -1;
  opacity: 0.04;
`;
