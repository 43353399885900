'use client';

import { useEffect, useRef, useState } from 'react';

import { TextElement, Wrapper } from './styles';

export const Text = ({ idx, text, positionHeader, length }) => {
  const [isActive, setIsActive] = useState(false);
  const [isActiveBar, setIsActiveBar] = useState(false);

  const refText = useRef(null);

  useEffect(() => {
    const setActive = (idx, position, heightElement) => {
      if (idx === 0) {
        return position > positionHeader - 50;
      }
      if (idx === length) {
        return position <= positionHeader + heightElement;
      }

      return (
        position > positionHeader - heightElement && position <= positionHeader + heightElement
      );
    };

    function updatePosition() {
      const position = refText.current?.getBoundingClientRect();
      let heightElement = refText.current?.clientHeight / 2;
      let result = setActive(idx, position.top, heightElement);
      setIsActive(result);
      setIsActiveBar(position.top <= positionHeader + heightElement);
    }
    window.addEventListener('scroll', updatePosition, { passive: true });

    return () => window.removeEventListener('scroll', updatePosition, { passive: true });
  }, [positionHeader, idx, length]);

  return (
    <Wrapper $isActive={isActive} $isFirst={idx === 0} $isActiveBar={isActiveBar}>
      <TextElement ref={refText} $isFirst={idx === 0} $isActive={isActive}>
        {text}
      </TextElement>
    </Wrapper>
  );
};

export default Text;
